import React from "react";
import "./TypingAnimation.css";

const TypingAnimation = ({ text }) => {
  return (
    <div className="terminal bg-transparent h-11">
      <div className="text-container">
        <span className="typed-text text-3xl text-white-500">{text}</span>
      </div>
      <span className="cursor text-3xl bg-blue-400">&nbsp;</span>
    </div>
  );
};

export default TypingAnimation;
