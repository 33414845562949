import { useAtom } from "jotai";
import examples from './examples.json';
import { setupDataAtom } from "../InitialForm";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./styles.css";

const IssueExample = () => {
  const [setupData] = useAtom(setupDataAtom);
  const [text, setText] = useState("");
  const title = examples.title;

  useEffect(() => {
    console.log("setupData.style:", setupData.style); // Debugging log

    const sectionsMapping = {
      "has_steps": "steps",
      "has_impact": "impact",
      "has_location": "location",
      "has_culprit": "culprit",
      "has_expected": "expected"
    };

    let newText = "";
    let styleExamples = examples[setupData.style];
    
    if (!styleExamples) return;  // Safety check for style examples

    for (const section in sectionsMapping) {
      if (setupData.sections.includes(section)) {
        newText += styleExamples[sectionsMapping[section]] + "\n\n";
      }
    }

    setText(newText);
    console.log("text:", text); // Debugging log
  }, [setupData, text]);


  return (
    <div className="gap-y-10 min-w-full min-h-full">
      <h2 className=" text-white text-3xl normal-case">Issue Example</h2>
      <div className="h-5/6 text-white text-sm normal-case mt-14 via-blue-400/0 from-blue-400 p-[2px] to-blue-400/0 bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] font-sans">
        <span className=" h-full block pl-8 pt-8 bg-blue-950">
          <h2 className="font-semibold text-2xl mb-8 text-white/20">{title}</h2>
          <div className="wysiwyg px-10">
          <ReactMarkdown children={text} remarkPlugins={[remarkGfm]}/>
          </div>
        </span>
      </div>
    </div>
  );
};

export default IssueExample;
