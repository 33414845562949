import React, { useState } from 'react';

function Tooltip({ children, content, position = "top" }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipStyles = {
    top: { tooltip: "bottom-full" },
    right: { tooltip: "left-full ml-2 top-1/2 transform -translate-y-1/2" },
    bottom: { tooltip: "top-full mt-2" },
    left: { tooltip: "right-full mr-2 top-1/2 transform -translate-y-1/2" },
    "top-left": { tooltip: "bottom-full right-1/4" },
    "top-right": { tooltip: "bottom-full left-1/4" },
    "right-top": { tooltip: "left-full ml-2 bottom-1/4" },
    "right-bottom": { tooltip: "left-full ml-2 top-1/4" },
    "bottom-left": { tooltip: "top-full mt-2 right-1/4" },
    "bottom-right": { tooltip: "top-full mt-2 left-1/4" },
    "left-top": { tooltip: "right-full mr-2 bottom-1/4" },
    "left-bottom": { tooltip: "right-full mr-2 top-1/4" },
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div className={`absolute text-xs min-w-60 w-64 z-10 p-2 bg-blue-950/90 text-white border border-gray-300 shadow-lg ${tooltipStyles[position].tooltip}`}>
          {content}
        </div>
      )}
    </div>
  );
}

export default Tooltip;
