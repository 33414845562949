import React from "react";

const mindsdblogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="129"
    height="20"
    viewBox="0 0 129 20"
    fill="none"
  >
    <path
      d="M25.3749 10.0725C25.7652 13.1351 26.1274 16.0106 26.5034 18.883C26.548 19.2271 26.4767 19.3485 26.0913 19.3678C23.7706 19.4831 23.772 19.4923 22.9331 17.3791C22.5838 16.4998 22.2434 15.617 21.8714 14.7464C21.7895 14.6033 21.7593 14.4372 21.7857 14.2751C21.8121 14.1129 21.8935 13.9643 22.0166 13.8535C23.1144 12.6543 24.1836 11.4225 25.3749 10.0725Z"
      fill="#00A587"
    />
    <path
      d="M11.734 12.6395C12.0664 14.3254 12.3943 15.91 12.683 17.5016C12.7721 17.9943 13.173 18.6202 12.83 18.9533C12.5342 19.2396 11.8775 18.8346 11.3727 18.853C10.0317 18.9029 9.4075 18.2831 9.18964 16.9817C9.06178 16.2177 9.00519 15.6886 9.6454 15.0969C10.3927 14.3212 11.0903 13.5005 11.734 12.6395Z"
      fill="#00A587"
    />
    <path
      d="M15.5205 15.7171C15.5205 14.7703 15.5058 13.8235 15.5276 12.8771C15.5361 12.5054 15.431 12.38 15.0295 12.3669C12.2911 12.2777 9.55321 12.1696 6.81595 12.0425C6.38602 12.0228 6.21628 12.1429 6.12495 12.5611C5.7026 14.4976 5.22322 16.4228 4.81067 18.3616C4.69483 18.906 4.48366 19.1067 3.90494 19.0449C3.2628 18.9794 2.61635 18.9648 1.97183 19.0011C1.48621 19.0252 1.39266 18.8258 1.39978 18.4098C1.44166 15.5019 1.50359 12.5939 1.47508 9.68691C1.46982 8.79235 1.77346 7.92254 2.33627 7.21994C3.85103 5.1488 5.3101 3.04041 6.77763 0.937264C6.85679 0.793253 6.98136 0.678444 7.13259 0.610137C7.28381 0.54183 7.4535 0.523719 7.61609 0.558535C13.088 1.13451 18.5619 1.69252 24.0379 2.23255C24.3804 2.27294 24.7075 2.39596 24.99 2.59067C28.2485 4.67408 31.5012 6.76495 34.7762 8.82294C35.2663 9.12978 35.2797 9.30511 34.8087 9.58258C34.7073 9.63645 34.6168 9.70804 34.5414 9.79385C33.5983 10.9927 32.492 10.9388 31.1555 10.4075C29.54 9.76712 27.8364 9.34237 26.18 8.79796C26.0223 8.73508 25.8514 8.71127 25.6822 8.72862C25.513 8.74597 25.3507 8.80395 25.2096 8.89746C23.6859 9.82848 22.1553 10.7481 20.6177 11.6563C20.4792 11.7293 20.3609 11.8345 20.2731 11.9626C20.1853 12.0908 20.1306 12.2382 20.1138 12.3919C19.7765 14.3907 19.3907 16.3807 19.0806 18.3848C18.9915 18.959 18.7835 19.1409 18.2034 19.101C17.4668 19.0582 16.7282 19.057 15.9914 19.0975C15.5544 19.1168 15.4425 18.966 15.4568 18.5562C15.4853 17.6098 15.4657 16.6621 15.4657 15.7153L15.5205 15.7171Z"
      fill="#00A587"
    />
    <path
      d="M48.2467 9.98389C49.3492 8.34755 50.9428 7.8751 52.7837 8.11944C53.8055 8.20244 54.7565 8.66654 55.4419 9.41676C56.1273 10.167 56.4953 11.1466 56.4707 12.155C56.5413 14.239 56.4769 16.3275 56.5078 18.4135C56.5155 18.9483 56.2126 18.9153 55.8504 18.9204C55.4536 18.9239 55.1368 18.9437 55.1476 18.3714C55.1816 16.5972 55.1646 14.8229 55.1558 13.0513C55.1493 12.6873 55.1207 12.324 55.0703 11.9634C54.7442 9.40093 52.4571 8.97258 50.7424 9.54034C49.6604 9.89518 48.9783 10.9703 48.9638 12.3385C48.9432 14.2682 48.9443 16.1981 48.967 18.1281C48.9731 18.6558 48.8969 18.9391 48.242 18.9391C47.5763 18.9391 47.5161 18.6279 47.5207 18.1159C47.5372 16.291 47.5568 14.4661 47.5207 12.6412C47.4692 10.2024 45.7473 8.87779 43.4566 9.46886C42.8989 9.60845 42.4017 9.92098 42.0386 10.3602C41.6755 10.7994 41.4658 11.342 41.4405 11.9076C41.2867 13.9876 41.2601 16.0748 41.3607 18.158C41.3694 18.9873 40.9444 18.9538 40.3817 18.9184C40.138 18.9037 39.9696 18.8418 39.9778 18.56C40.0407 16.2687 39.8062 13.9688 40.1004 11.6861C40.3271 9.92661 41.289 8.65018 43.098 8.23907C45.0311 7.79703 46.8143 8.07433 48.0869 9.79025C48.1271 9.84247 48.1761 9.89721 48.2467 9.98389Z"
      fill="white"
    />
    <path
      d="M118.54 9.92836C119.225 9.12119 120.14 8.53354 121.166 8.24066C122.192 7.94778 123.284 7.96302 124.302 8.28442C126.912 8.97789 128.237 11.181 128.031 14.3289C127.975 15.3644 127.605 16.3595 126.969 17.1863C126.333 18.0131 125.46 18.6337 124.462 18.9683C123.425 19.3181 122.306 19.3632 121.243 19.0981C120.18 18.833 119.218 18.2692 118.475 17.4754C117.603 16.4764 117.132 15.1979 117.151 13.8808C117.127 9.71191 117.145 5.54248 117.139 1.37356C117.139 0.907195 117.193 0.555388 117.805 0.541701C118.441 0.527508 118.549 0.857006 118.547 1.37964C118.533 4.1667 118.54 6.95375 118.54 9.92836ZM118.54 13.6466C118.462 17.0739 121.354 18.659 124.254 17.6442C126.049 17.0161 127.027 14.9362 126.648 12.7777C126.366 11.1698 125.602 9.93191 123.901 9.48278C120.834 8.66613 118.535 10.4662 118.54 13.6466Z"
      fill="white"
    />
    <path
      d="M112.256 9.95593C112.256 6.96206 112.26 4.15321 112.25 1.34486C112.25 0.866834 112.317 0.533783 112.931 0.541387C113.438 0.549498 113.637 0.721346 113.635 1.23537C113.618 5.48793 113.664 9.74049 113.604 13.992C113.56 17.1572 111.227 19.2711 107.992 19.2219C104.937 19.1758 102.72 16.9063 102.705 13.8085C102.691 10.9191 104.058 8.86656 106.527 8.26535C108.654 7.74575 110.597 8.09705 112.256 9.95593ZM108.147 17.9278C110.656 17.9313 112.25 16.2732 112.256 13.6524C112.261 10.9611 110.689 9.30251 108.139 9.3091C105.635 9.31569 104.069 10.9708 104.062 13.6225C104.054 16.2392 105.651 17.9243 108.147 17.9278Z"
      fill="white"
    />
    <path
      d="M86.8157 10.1677C86.8157 7.21745 86.824 4.36247 86.8085 1.50749C86.806 1.00666 86.9069 0.710111 87.503 0.728867C87.9755 0.743568 88.161 0.915415 88.1594 1.38787C88.1435 5.71393 88.2001 10.041 88.1192 14.365C88.0636 17.3447 85.7204 19.4226 82.6579 19.4084C81.9588 19.4271 81.2629 19.3091 80.6106 19.0611C79.9583 18.8132 79.3626 18.4402 78.8579 17.9639C78.3533 17.4876 77.9498 16.9175 77.6708 16.2865C77.3919 15.6556 77.2431 14.9764 77.2331 14.2885C77.1212 11.5187 78.2604 9.42813 80.4666 8.65102C82.8325 7.81764 85.0042 8.13447 86.8157 10.1677ZM82.7207 18.1137C85.1995 18.1021 86.8425 16.3664 86.8142 13.7953C86.7823 11.1202 85.1763 9.47173 82.6249 9.49454C80.1405 9.51685 78.5706 11.2105 78.5866 13.851C78.6025 16.4586 80.2188 18.1254 82.7207 18.1137Z"
      fill="white"
    />
    <path
      d="M95.6725 19.2253C94.1964 19.2146 92.8779 18.8507 91.9943 17.5849C91.763 17.2539 91.4605 16.8575 91.9892 16.5138C92.4359 16.2233 92.7336 16.4043 93.0521 16.7966C93.642 17.4433 94.4444 17.8651 95.3187 17.9881C96.193 18.111 97.0834 17.9274 97.8339 17.4693C98.0842 17.3197 98.2864 17.1033 98.417 16.8453C98.5476 16.5873 98.6012 16.2982 98.5717 16.0114C98.5544 15.7354 98.4512 15.4713 98.276 15.255C98.1009 15.0387 97.8623 14.8807 97.5928 14.8024C96.5851 14.474 95.5561 14.2128 94.5127 14.0207C94.0743 13.9188 93.6453 13.7808 93.2303 13.6081C92.8512 13.4483 92.5145 13.2049 92.2461 12.8968C91.9777 12.5887 91.7847 12.2241 91.6822 11.831C91.5797 11.438 91.5702 11.027 91.6546 10.6298C91.739 10.2326 91.915 9.85981 92.169 9.54009C93.4962 7.69743 98.1296 7.36641 99.5254 9.75705C99.7062 10.0667 100.109 10.4125 99.5836 10.7561C99.1358 11.0497 98.8107 10.9726 98.4645 10.4941C97.5062 9.16192 94.9203 8.89326 93.6477 9.94005C93.4151 10.0956 93.2326 10.3133 93.1217 10.5676C93.0108 10.8218 92.9761 11.102 93.0217 11.375C93.0673 11.648 93.1913 11.9025 93.3791 12.1085C93.5668 12.3145 93.8106 12.4633 94.0815 12.5375C94.9301 12.8259 95.8276 12.977 96.704 13.1863C97.3263 13.3198 97.9269 13.5373 98.4887 13.8327C98.9365 14.0533 99.3134 14.3917 99.5776 14.8103C99.8417 15.229 99.9828 15.7114 99.985 16.2042C99.9873 16.6969 99.8508 17.1806 99.5906 17.6016C99.3303 18.0226 98.9566 18.3644 98.5109 18.5891C97.6427 19.0551 96.6602 19.2753 95.6725 19.2253Z"
      fill="white"
    />
    <path
      d="M74.7799 15.4446C74.7799 16.3829 74.7541 17.3202 74.7902 18.2595C74.8098 18.7664 74.6552 18.9433 74.1204 18.9276C73.6325 18.9129 73.3991 18.8222 73.4063 18.2757C73.4362 16.5294 73.4316 14.7825 73.414 13.0362C73.3862 10.3718 71.4139 8.8576 68.777 9.45881C68.0678 9.60788 67.4316 9.99055 66.9734 10.5436C66.5152 11.0966 66.2625 11.7869 66.2571 12.5004C66.1787 14.376 66.2019 16.2516 66.2148 18.1272C66.2184 18.6544 66.1453 18.9276 65.4935 18.9352C64.8026 18.9433 64.7866 18.6062 64.8031 18.1125C64.8706 16.1355 64.6624 14.1499 64.9159 12.1785C65.2601 9.49835 67.1762 7.99128 70.0826 8.06326C70.6825 8.05198 71.2787 8.15713 71.8373 8.37272C72.3958 8.5883 72.9056 8.91009 73.3377 9.31968C73.7697 9.72927 74.1155 10.2187 74.3552 10.7598C74.5949 11.301 74.7238 11.8833 74.7346 12.4735C74.8016 13.4595 74.7454 14.4505 74.7454 15.443L74.7799 15.4446Z"
      fill="white"
    />
    <path
      d="M61.3323 13.6744C61.3323 15.1845 61.3117 16.6946 61.3436 18.2042C61.3549 18.734 61.2174 18.9215 60.6408 18.9281C60.0154 18.9352 59.9664 18.6539 59.971 18.1677C59.9865 15.2002 59.9819 12.2322 59.9757 9.2642C59.9757 8.79175 59.9612 8.38064 60.6455 8.36543C61.3297 8.35022 61.3457 8.73142 61.341 9.22263C61.3235 10.7069 61.3323 12.1906 61.3323 13.6744Z"
      fill="white"
    />
    <path
      d="M59.499 5.08352C59.5033 4.78285 59.627 4.49577 59.8437 4.28366C60.0603 4.07156 60.3527 3.95127 60.6583 3.94852C60.8164 3.94431 60.9736 3.973 61.1197 4.03272C61.2658 4.09244 61.3974 4.18184 61.5059 4.29507C61.6144 4.40829 61.6974 4.54279 61.7494 4.68976C61.8014 4.83673 61.8213 4.99284 61.8078 5.1479C61.7922 5.44437 61.6594 5.72311 61.4377 5.92448C61.216 6.12586 60.923 6.2339 60.6214 6.22549C60.3198 6.21708 60.0335 6.09288 59.8238 5.87947C59.614 5.66607 59.4975 5.38038 59.499 5.08352Z"
      fill="white"
    />
  </svg>
);

const Powered = () => {
  return (
    <div className="absolute bottom-32 left-32">
      <div className="flex flex-col">
        <div className="text-white/100 mb-3">Powered by</div>
        <a href="https://mindsdb.com" target="_blank" rel="noreferrer">
        <span className=" text-white">{mindsdblogo}</span>
        </a>
      </div>
    </div>
  );
};

export default Powered;
