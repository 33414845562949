const Textarea = ({ defaultValue, onChange, placeholder, lines, ...props }) => {
  const textValue = defaultValue;
  const containerClass = 
  
  lines === "1"
    ? "px-10 py-5 border-2 border-blue-900 hover:border-blue-400 rounded-none" 
    : "px-10 py-5 border-2 border-blue-900 hover:border-blue-400 rounded-none h-full";

  return (
    <div className={containerClass}>
      <textarea
        {...lines && {rows: lines}}
        value={textValue || ""}
        onChange={onChange}
        className="bg-transparent text-white resize-none min-h-[1.5rem] h-full w-full focus:outline-none"
        placeholder={placeholder || "Type here..."}
        {...props}
      />
    </div>
  );
};

export default Textarea;
