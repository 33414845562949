import React, { useState } from "react";
import TextArea from "../../components/ui/TextArea";
import Button from "../../components/ui/Button";
import { toastAtom } from "../../App";
import { useAtom } from "jotai";

function Contact() {
  const [formData, setFormData] = useState({ email: "", body: "" });
  const [, setToast] = useAtom(toastAtom);

  // Function to validate email format
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    // Check if email is valid
    if (!isValidEmail(formData.email)) {
      setToast({
        message: `Not valid email address`,
        icon: "error",
        isVisible: true,
      });
      return;
    }

    const response = await fetch("https://api.theissue.ai/contact_us", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setToast({
        message: `Email sent successfully`,
        icon: "success",
        isVisible: true,
      });
    //   navigate("/");
    window.location.href = "/";

    } else {
      setToast({
        message: `Error sending the email`,
        icon: "error",
        isVisible: true,
      });
    }
  };

  return (
    <div className="bg-gradient-to-r from-gray-900  via-blue-950 via-80% to-blue-800 font-mono">
      <div className="flex flex-col min-h-full min-w-full place-items-center justify-center ">
        <div className="h-screen w-2/3">
          <div className="flex flex-col min-h-full min-w-full place-items-center justify-center">
            <div className="w-full space-y-4">
            <h1 className="text-3xl text-white my-5">Contact Us</h1>
            <TextArea
              placeholder="Email"
              lines="1"
              value={formData.email || ""}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
            />

            <TextArea
              placeholder="Message Body"
              lines="5"
              value={formData.body || ""}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, body: e.target.value }))
              }
            />

            <Button className="mt-5" label="Contact us" onClick={handleSubmit}>
              Send Email
            </Button>
              </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
