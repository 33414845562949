// src/DropdownSelect.jsx
import React, { useState } from "react";

const DropdownSelect = ({ options, onChange, defaultValue, label, ...props }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  const handleOnChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="relative px-10 py-5 border-2 border-blue-900 hover:border-blue-400  rounded-none flex">
    <label className="text-white">{label}</label>
      <select
        value={selectedValue}
        onChange={handleOnChange}
        className="ms-4 bg-transparent w-full h-full text-blue-400 font-semibold uppercase"
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownSelect;
