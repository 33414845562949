import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { toastAtom } from "../../../App";

const errorIcon = (
  <svg width="30" height="30" viewBox="0 0 24 24">
    <path
      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const warningIcon = (
  <svg width="30" height="30" viewBox="0 0 24 24">
    <path
      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    />
  </svg>
);

const successIcon = (
  <svg width="30" height="30" viewBox="0 0 24 24">
    <path
      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const Toast = ({ message, icon, isVisible }) => {
  const [, setToast] = useAtom(toastAtom);

  const getBorderColor = () => {
    switch (icon) {
      case "error":
        return "border-red-700 stroke-red-700";
      case "warning":
        return "border-yellow-500 stroke-yellow-500";
      case "success":
        return "border-green-500 stroke-green-500";
      default:
        return "border-gray-900 stroke-gray-900";
    }
  };

  useEffect(() => {
    if (isVisible) {
      const timeoutId = setTimeout(() => {
        setToast({ message: "", icon: "", isVisible: false });
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [isVisible, setToast]);

  if (!isVisible) return null;

  return (
    <div
      className={`fixed bottom-6 right-6 bg-gray-900 text-white p-4 border-2 ${getBorderColor()} flex font-mono items-center space-x-2 leading-loose tracking-wider`}
    >
      <span className="stroke-[1.5] px-3 py-5">
        {icon === "error"
          ? errorIcon
          : icon === "warning"
          ? warningIcon
          : icon === "success"
          ? successIcon
          : ""}
      </span>
      <span className="font-light text-xl max-w-xs">{message}</span>
    </div>
  );
};

export default Toast;
