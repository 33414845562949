import React from "react";
import TextArea from "../../ui/TextArea";
import MultiForm from "../../ui/MultiForm";
import MultiFormHeader from "../../ui/MultiFormHeader";
import MultiSelectOption from "../../ui/MultiSelectOption";
import SingleSelect from "../../ui/SingleSelect";
import { useAtom, atom } from "jotai";
import { toastAtom } from "../../../App";

export const setupDataAtom = atom({
  name: "",
  style: "Formal",
  sections: [],
});

const Step1 = ({ formData, setFormData }) => (
  <div>
    <MultiFormHeader
      title="Set your Lingo"
      text="Add a bit of flavour to your issues! Start with Lingo name."
    />
    <TextArea
      placeholder="E.g. “Friendly manager”"
      lines="1"
      value={formData.name || ""}
      onChange={(e) =>
        setFormData((prev) => ({ ...prev, name: e.target.value }))
      }
    />
  </div>
);

const Step2 = ({ formData, setFormData }) => (
  <div>
    <MultiFormHeader
      title="Choose Your Tone"
      text="Choose the Tone of the writing. Check example on the right side!"
    />
    <SingleSelect
      options={[
        { value: "Formal", label: "Formal" },
        { value: "Casual", label: "Casual" },
      ]}
      value={formData.style || "Formal"}
      onChange={(value) => setFormData((prev) => ({ ...prev, style: value }))}
    />
  </div>
);

const Step3 = ({ formData, setFormData }) => (
  <div>
    <MultiFormHeader
      title="Choose sections to include"
      text="Choose section you want to include in your issues."
    />
    <MultiSelectOption
      options={[
        { value: "has_steps", label: "Steps to reproduce" },
        { value: "has_impact", label: "Impact" },
        { value: "has_location", label: "Location" },
        // { value: "has_culprit", label: "Suspected culprit" },
        { value: "has_expected", label: "Expected behaviour" },
      ]}
      value={formData.sections || []}
      onChange={(values) =>
        setFormData((prev) => ({ ...prev, sections: values }))
      }
    />
  </div>
);



const InitialForm = () => {
  const [setupData, setSetupData] = useAtom(setupDataAtom);  

  const onSubmitForm = () => {
    if (setupData.name === "") {
      setToast({
        message: "Missing lingo name",
        icon: "error",
        isVisible: true,
      });
      return;
    }

    //hit /create_lingo endpoint
    fetch("https://api.theissue.ai/create_lingo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: "https://theissue.ai",
      },
      body: JSON.stringify({
        user_id: localStorage.getItem("user_id"),
        name: setupData.name,
        style: setupData.style,
        sections: setupData.sections,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = "/issue";
      })
      .catch((error) => {
        setToast({
          message: "Error creating lingo",
          icon: "error",
          isVisible: true,
        });
      });
  };

  const steps = [
    { component: <Step1 formData={setupData} setFormData={setSetupData} /> },
    { component: <Step2 formData={setupData} setFormData={setSetupData} /> },
    { component: <Step3 formData={setupData} setFormData={setSetupData} /> },
  ];

  const [, setToast] = useAtom(toastAtom);

  return (
    <div className="min-w-full min-h-full">
      <MultiForm onSubmit={() => onSubmitForm()} steps={steps} />
    </div>
  );
};

export default InitialForm;
