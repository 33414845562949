import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./styles.css";

const content = `
# Privacy Policy for GitHub App - theissue.ai

**Effective Date:** October 9, 2023

This **Privacy Policy** ("Policy") outlines the practices of **theissue.ai** ("we," "us," or "our") concerning the collection, use, and disclosure of personal information when you use our GitHub App named "**theissue.ai**" ("App"). Protecting your privacy is important to us, and we are committed to safeguarding your personal information.

By using our App, you consent to the practices described in this Policy. Please read this Policy carefully to understand how we collect, use, and protect your personal information.

## 1. Information We Collect

### 1.1. GitHub Data
To provide our services, we may collect and access certain information from your GitHub account, including but not limited to:
- Your username and email address.
- Repository and issue data, such as issue titles, descriptions, and comments.
- Publicly available information from your GitHub profile.

### 1.2. Usage Information
We may collect information about how you use our App, including:
- App settings and configurations.
- Logs of interactions with the App.
- Error messages and performance data.

## 2. How We Use Your Information

### 2.1. Improving Issue Quality
We use the collected information to enhance the quality of issues reported on GitHub. This includes identifying trends, patterns, and potential issues to provide recommendations and insights to users.

### 2.2. Communication
We may use your email address to send you notifications related to the App, updates, and important announcements.

### 2.3. Analytics
We may use aggregated and anonymized data for analytical purposes to understand user behavior, improve the App, and develop new features.

## 3. Data Security

We take appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. While we strive to use industry-standard security practices, no method of transmission over the internet or electronic storage is entirely secure. Therefore, we cannot guarantee the absolute security of your data.

## 4. Sharing of Information

We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted service providers or partners who assist us in delivering the App's functionality. These third parties are obligated to keep your information confidential and secure.

## 5. Your Choices

You have the following rights regarding your personal information:

### 5.1. Access
You can request access to your personal information that we hold and receive a copy of it.

### 5.2. Correction
You can request corrections to any inaccurate or incomplete personal information we hold about you.

### 5.3. Deletion
You can request the deletion of your personal information, subject to legal limitations.

## 6. Updates to This Policy

We may update this Privacy Policy from time to time. If we make significant changes, we will notify you through the App or via email. We encourage you to review this Policy periodically to stay informed about how we are protecting your information.

## 7. Contact Us

If you have any questions, concerns, or requests related to your privacy or this Policy, please contact us at [Your Contact Information].

By using our GitHub App, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and sharing of your information as described herein.

**theissue.ai**
**October 9, 2023**
`;


function Privacy() {
  return (
    <div className="bg-gradient-to-r from-gray-900  via-blue-950 via-80% to-blue-800">
      <div className="flex flex-col min-h-full min-w-full place-items-center justify-center">
        <div className="h-screen w-2/3">
        <div className="px-10 py-10 flex wysiwyg text-white pr-4 overflow-y-scroll scroll-smooth">
            <ReactMarkdown children={content} remarkPlugins={[remarkGfm]}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
