import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./styles.css";

const content = `
# Terms and Conditions for GitHub App - theissue.ai

**Effective Date:** October 9, 2023

These **Terms and Conditions** ("Terms") govern your use of the GitHub App named "**theissue.ai**" ("App") provided by **theissue.ai** ("we," "us," or "our"). By using the App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the App.

## 1. Use of the App

1.1. **License:** We grant you a non-exclusive, non-transferable, revocable license to use the App in accordance with these Terms.

1.2. **Restrictions:** You may not:
   - Modify, reverse engineer, decompile, or disassemble the App.
   - Use the App for any unlawful or unauthorized purpose.
   - Share your access credentials with third parties.
   - Attempt to gain unauthorized access to the App or its related systems.

1.3. **Updates:** We may release updates, improvements, or new features for the App, and you agree to install and use these updates to maintain compatibility and functionality.

## 2. Privacy

2.1. **Privacy Policy:** Your use of the App is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using the App, you consent to the practices described in the Privacy Policy.

## 3. Intellectual Property

3.1. **Ownership:** We retain all rights, title, and interest in and to the App, including all intellectual property rights.

3.2. **Feedback:** If you provide us with feedback, suggestions, or ideas regarding the App, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sublicensable license to use, reproduce, and incorporate such feedback into the App or our other products or services.

## 4. Termination

4.1. **Termination:** We reserve the right to terminate or suspend your access to the App at any time, with or without cause, and without prior notice.

4.2. **Effect of Termination:** Upon termination, you must cease all use of the App, and any provisions of these Terms that, by their nature, should survive termination (such as intellectual property provisions and limitations of liability) will continue to apply.

## 5. Disclaimer of Warranties

5.1. **No Warranty:** The App is provided "as is" and "as available" without any warranties, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.

## 6. Limitation of Liability

6.1. **Limitation:** We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of the App.

## 7. Governing Law

7.1. **Governing Law:** These Terms are governed by and construed in accordance with the laws of Ontario, without regard to its conflict of law principles.

## 8. Contact Us

If you have any questions, concerns, or inquiries regarding these Terms, please contact us at [Your Contact Information].

By using the App, you acknowledge that you have read and understood these Terms and agree to comply with and be bound by them.

**theissue.ai**
**October 9, 2023**
`;


function Terms() {
  return (
    <div className="bg-gradient-to-r from-gray-900  via-blue-950 via-80% to-blue-800">
      <div className="flex flex-col min-h-full min-w-full place-items-center justify-center">
        <div className="h-screen w-2/3">
        <div className="px-10 py-10 flex wysiwyg text-white pr-4 overflow-y-scroll scroll-smooth">
            <ReactMarkdown children={content} remarkPlugins={[remarkGfm]}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
