import React from "react";
import TwoColumnLayout from "../../components/layouts/TwoColumnLayout";
import IssueForm from "../../components/layouts/IssueForm";
import WYSIWYG from "../../components/layouts/wysiwyg";
import { atom } from 'jotai'

export const formDataAtom = atom({
  repository: "",
  owner: "",
  lingo: "",
  issueTitle: "",
  issueDescription: "",
  issuePreview: "",
});

export const isGeneratingAtom = atom(false);

export const repositoryListAtom = atom([]);

// this is the atom that will hold the list of lingo      // this is an example of how the json response will look like
      // [{'name': 'loling'}, {'name': 'lollingo'}, {'name': 'lol'}]
export const lingoListAtom = atom([]);

function IssueCreation() {
  console.log(formDataAtom)
  console.log(repositoryListAtom)
  return (
    <TwoColumnLayout
      leftContent={
        <div className="min-h-full min-w-full p-16 m-0 h-full  bg-blue-950 ">
            <IssueForm />
        </div>
      }
      rightContent={
        <div className="flex min-h-full min-w-full p-16 m-0 h-full  bg-gray-900">
          <WYSIWYG />
        </div>
      }
    />
  );
}

export default IssueCreation;
