import React from 'react';

const TwoColumnLayout = ({ leftContent, rightContent }) => {
  return (
    <div className="flex h-screen w-full overflow-hidden">
      <div className="flex-1 overflow-x-hidden border-r font-mono w-screen h-screen text-white border-none">
        {leftContent}
      </div>
      <div className="flex-1 overflow-x-hidden border-r font-mono w-screen h-screen text-white border-none">
        {rightContent}
      </div>
    </div>
  );
}

export default TwoColumnLayout;
