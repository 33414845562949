import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Button from "../../ui/Button";
import ButtonIconed from "../../ui/ButtonIconed";
import Textarea from "../../ui/TextArea";
import "./styles.css";
import { useAtom } from "jotai";
import { formDataAtom, isGeneratingAtom } from "../../../pages/IssueCreation";
import { toastAtom } from "../../../App";



const WYSIWYG = () => {
  const [content, setContent] = useState(""); // State to hold the content
  const [isEditing, setIsEditing] = useState(true); // State to toggle between edit and preview mode
  const [formData, setFormData] = useAtom(formDataAtom);
  const [toast, setToast] = useAtom(toastAtom);
  const [isGenerating, setIsGenerating] = useAtom(isGeneratingAtom);



  const onGenerate = () => {
    // set loading state
    setIsGenerating(true);
    
    fetch("https://api.theissue.ai/generate_issue", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'https://theissue.ai',
      },
      body: JSON.stringify({
        repository: formData.repository,
        owner: formData.owner,
        issueTitle: formData.issueTitle,
        issueDescription: formData.issueDescription,
        lingo: formData.lingo,
        user_id: localStorage.getItem('user_id'),
      })
    })
    .then((response) => response.json())
    .then((json) => {
      // set the preview data to the response
      setFormData((prev) => ({ ...prev, issuePreview: json.issuePreview }));
      setIsGenerating(false); // move this here
    })
    .catch((error) => {
      // Show toast error message
      setToast({message: `Error generating issue: ${error}`, icon: "error", isVisible: true});
      setIsGenerating(false); // also set the state to false if there was an error
    });
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
    setFormData(prev => ({ ...prev, issuePreview: e.target.value }));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(content)
      .then(() => {
        setToast({message: "Content copied to clipboard!", icon: "success", isVisible: true});
      })
      .catch(error => {
        setToast({message: `Error copying to clipboard: ${error}`, icon: "error", isVisible: true});
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("username");

    window.location.reload();
  }

  // we need to listen to changes in the formData object to update the content
  React.useEffect(() => {
    const issuePreview = formData.issuePreview;
    setContent(formData.issuePreview);
    console.log("issuePreview", issuePreview);
  }, [formData]);

  // create a function to handle the creation of the issue. it will need to access a formData object that is on another component (IssueForm)
  const handleIssueCreation = () => {
    const user_id = localStorage.getItem("user_id");
    const { repository, owner, issueTitle, issuePreview} = formData;



    fetch(`https://api.theissue.ai/create_issue`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: "https://theissue.ai",
      },
      body: JSON.stringify({
        user_id,
        repository,
        owner,
        issueTitle,
        issuePreview
      }),
    })
      .then((response) => response.json())
      .then((json) => {

        if (json.error){
          setToast({message: `Error Creating Issue: ${json.error}`, icon: "error", isVisible: true});
          return;
        }

        setToast({message: "issue Created! ", icon: "success", isVisible: true});
        // open the issue url in a new tab
        console.log(json);
        window.open(json.html_url, "_blank");
        
        // wait for the toast isVisible to be false and then navigate to the issue page
        if (toast.isVisible === false){
          setTimeout(() => {
            // store the selected repository and lingo in local storage
            localStorage.setItem("repository", repository);
            localStorage.setItem("lingo", formData.lingo);
            window.location.href = "/issue";
          }, 3000);
        }
      })
      .catch((error) => {
        //TODO USE TOAST
        setToast({message: `Error Creating Issue: ${error}`, icon: "error", isVisible: true});
      });
  };
  

  const isContentEmpty = !content.trim();

  return (
    <div className="flex flex-col min-w-full max-h-full">
      <div className="flex justify-between mt-4 mb-10 mr-0">
        <div>

        <Button
          label="Edit Issue"
          variant={isEditing ? undefined : "secondary"}
          onClick={() => setIsEditing(true)}
        />
                <Button
          label="Issue Preview"
          variant={isEditing ? "secondary" : undefined}
          onClick={() => setIsEditing(false)}
        />
        </div>
        <div>
        <ButtonIconed icon='logOut' className="justify-self-end mr-0" onClick={handleLogout}/>
        </div>
      </div>

      <div className="flex flex-col gap-6 mt-6 flex-grow h-20 w-full">
        {isEditing ? (
          <Textarea
            className="flex h-full w-full bg-transparent focus-visible:outline-none"
            value={content}
            placeholder={"Issue will be generated here..."}
            onChange={handleContentChange}
          />
        ) : (
          <div className="px-10 flex h-full w-full wysiwyg pr-4 overflow-y-scroll scroll-smooth">
            <ReactMarkdown children={content} remarkPlugins={[remarkGfm]}/>
          </div>
        )}
      </div>
      <div className="mt-6 flex flex-row w-full align-bottom justify-between">
        <ButtonIconed icon='reload' onClick={() => onGenerate() } disabled={isGenerating ||!formData.issueTitle || !formData.issueDescription} />
        
        
        
        <div className="flex gap-3">
            <ButtonIconed icon='copy' onClick={copyToClipboard} disabled={isContentEmpty} />
            <Button label='Create Issue' variant='primary' onClick={handleIssueCreation} disabled={isGenerating || isContentEmpty} />
        </div>
      </div>
    </div>
    
  );
};

export default WYSIWYG;
