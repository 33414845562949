import React from "react";

const MultiStepIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div className="flex">
    <div className="mr-2 text-white/60">
        STEP
    </div>
    <div className=" text-white">
       {currentStep}/{totalSteps}
    </div>
    </div>
  );
};

export default MultiStepIndicator;
