// src/Button.jsx
import React from "react";

const reloadIcon = 
<svg width="24" height="24" viewBox="0 0 24 24">
<path  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

const cogIcon =
<svg width="24" height="24" viewBox="0 0 24 24">
  <path d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
  <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

const copyIcon =
<svg width="24" height="24" viewBox="0 0 24 24">
  <path d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
</svg>

const closeIcon =
<svg width="24" height="24" viewBox="0 0 24 24">
  <path d="M6 18L18 6M6 6l12 12" />
</svg>

const logOutIcon =
<svg width="24" height="24" viewBox="0 0 24 24">
  <path d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
</svg>



const ButtonIconed = ({
  label,
  onClick,
  type = "button",
  className = "",
  variant = "primary",
  icon,
  ...props
}) => {
  let tailwindStyles = "";

  switch (variant) {
    case "primary":
      tailwindStyles = 
        `bg-blue-400
        text-black
        hover:text-white
        hover:bg-blue-600
        disabled:bg-slate-700
        disabled:text-slate-500
        focus: text-black
        `;
      break;
    case "secondary":
      tailwindStyles = 
        `bg-gray-900
        text-blue-400
        hover:bg-blue-950
        hover:text-white
        hover:fill-white
        disabled:bg-slate-800
        disabled:cursor-not-allowed
        disabled:text-slate-600`;
      break;
    case "terciary":
      tailwindStyles = 
        `bg-none
        border-none
        text-blue-400
        hover:text-white
        hover:fill-white
        disabled:text-slate-600
        `;
      break;
    default:
      tailwindStyles = 
        `bg-blue-500
        hover:bg-blue-700
        text-white`;
  }

  return (
    <button
      type={type}
      className={
        `flex justify-center items-center
        text-center
        self-center
        focus:outline
        focus:outline-offset-4
        focus:outline-2
        focus:outline-white
        rounded-none uppercase
        font-semibold
        disabled:cursor-not-allowed
        w-16 h-16
        ${tailwindStyles}
        ${className}`}
      onClick={onClick}
      {...props}
    >
      <span className="flex justify-center items-center stroke-current stroke-[1.5] fill-none mx-auto my-auto align-middle justify-center">
        {icon === "reload" ? reloadIcon :
         icon === "cog" ? cogIcon :
        icon === "copy" ? copyIcon :
        icon === "close" ? closeIcon :
        icon === "logOut" ? logOutIcon :
        ""}
      </span>
    </button>
  );
};

export default ButtonIconed;
