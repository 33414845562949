import React from "react";

const MultiFormHeader  = ({ title, text }) => {
  return (
    <div className="gap-y-10">
    <h2 className=" text-white text-3xl normal-case mb-2 ">
        {title}
    </h2>
    <div className=" text-white/60 text-sm normal-case mb-8">
        {text}
    </div>
    </div>
  );
};

export default MultiFormHeader;
