import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Setup from "./pages/Setup";
import IssueCreation from "./pages/IssueCreation";
import SignInPage from "./pages/SignInLogIn";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import { DevTools } from "jotai-devtools";
import { atom, useAtom } from "jotai";
import Toast from "./components/ui/Toast";

export const toastAtom = atom({
  message: "",
  icon: "",
  isVisible: false,
});



function determineInitialRoute(userId, hasLingo) {
  if (userId !== null && userId !== undefined) {
    return hasLingo ? "/issue" : "/setup";
  } else {
    return "/sign-in";
  }
}

function withRedirection(WrappedComponent) {
  return (props) => {
    const [user_id] = useState(localStorage.getItem("user_id"));
    const [has_lingo, setHasLingo] = useState(localStorage.getItem("has_lingo") === "true");

    useEffect(() => {
      if (user_id !== null && user_id !== undefined) {
        fetch("https://api.theissue.ai/lingo", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Origin: "https://theissue.ai",
          },
          body: JSON.stringify({ user_id }),
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok " + response.statusText);
          }
          return response.json();
        })
        .then((json) => {
          const lingoNames = json.lingos.map((lingo) => ({
            value: lingo,
            label: lingo,
          }));
          if (lingoNames.length > 0) {
            setHasLingo(true);
            localStorage.setItem("has_lingo", true);
          } else {
            setHasLingo(false);
            localStorage.setItem("has_lingo", false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      }
    }, [user_id]);

    const route = determineInitialRoute(user_id, has_lingo);

    const currentRoute = window.location.pathname;
    if (route !== currentRoute) {
      return <Navigate to={route} replace />;
    }

    return <WrappedComponent {...props} />;
  };
}


function withUserIdRedierction(WrappedComponent) {
  return (props) => {
    const [user_id] = useState(localStorage.getItem("user_id"));

    if (user_id === null || user_id === undefined) {
      return <Navigate to="/sign-in" replace />;
    } else 
    return <WrappedComponent {...props} />;
  }  
  };



function App() {
  const [toast] = useAtom(toastAtom);

  const RedirectedSignInPage = withRedirection(SignInPage);
  const RedirectedSetup = withRedirection(Setup);
  const RedirectedIssueCreation = withRedirection(IssueCreation);
  const RedirectedLingoWithUser = withUserIdRedierction(Setup);


  return (
    <>
      {process.env.REACT_APP_PRODUCTION !== "true" && <DevTools isInitialOpen />}
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/sign-in" replace />} />
          <Route path="/sign-in" element={<RedirectedSignInPage />} />
          <Route path="/setup" element={<RedirectedSetup />} />
          <Route path="/issue" element={<RedirectedIssueCreation />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/new-lingo" element={<RedirectedLingoWithUser />} /> 
          {/* Add other routes as needed */}
        </Routes>
      </Router>
      <Toast
        message={toast.message}
        icon={toast.icon}
        isVisible={toast.isVisible}
      />
    </>
  );
}

export default App;
