// src/Button.jsx
import React from "react";

const Button = ({
  label,
  onClick,
  type = "button",
  className = "",
  variant = "primary",
  ...props
}) => {
  let tailwindStyles = "";

  switch (variant) {
    case "primary":
      tailwindStyles = 
        `bg-blue-400
        text-black
        fill-black
        hover:text-white
        hover:bg-blue-600
        disabled:bg-slate-700
        disabled:text-slate-500
        focus: text-black
        `;
      break;
    case "secondary":
      tailwindStyles = 
        `bg-gray-900
        text-blue-400
        hover:bg-blue-950
        hover:text-white
        disabled:bg-slate-800
        disabled:cursor-not-allowed
        disabled:text-slate-600`;
      break;
    case "terciary":
      tailwindStyles = 
        `bg-none
        border-none
        text-blue-400
        hover:text-white
        disabled:text-slate-600
        `;
      break;
    default:
      tailwindStyles = 
        `bg-blue-500
        hover:bg-blue-700
        text-white`;
  }

  return (
    <button
      type={type}
      className={
        `
        focus:outline
        focus:outline-offset-4
        focus:outline-2
        focus:outline-white
        rounded-none uppercase
        font-semibold
        px-10
        py-5
        disabled:cursor-not-allowed
        ${tailwindStyles}
        ${className}`}
      onClick={onClick}
      {...props}
    >
      {label}
    </button>
  );
};

export default Button;
