import React, {useState} from "react";
import MultiFormNav from "../MultiFormNav";

const MultiStepForm = ({ title, text, steps, onSubmit }) => {

    const totalSteps = steps.length;
    const [currentStep, setCurrentStep] = useState(1);

    const handleBack = () => {
      if (currentStep > 1) {
        setCurrentStep(prevStep => prevStep - 1);
      }
    };
  
    const handleNext = () => {
      if (currentStep < totalSteps) {
        setCurrentStep(prevStep => prevStep + 1);
      }
    };

  return (
    <div className="relative flex flex-col h-full justify-start ">
    {steps[currentStep - 1].component}
    <div className="self-end w-full absolute bottom-0 left-0">
    <MultiFormNav totalSteps={totalSteps} currentStep={currentStep} handleDone={onSubmit} handleBack={handleBack} handleNext={handleNext} />
    </div>
    </div>
  );
};

export default MultiStepForm;
