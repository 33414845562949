import React from "react";
import MultiStepIndicator from "../MultiStepIndicator";
import Button from "../Button";

const MultiFormNav = ({ totalSteps, currentStep, handleBack, handleDone, handleNext  }) => { 

  return (
    <div className="flex w-full items-center">
      <MultiStepIndicator currentStep={currentStep} totalSteps={totalSteps} />
      <div className="ml-auto">
        <Button label="back" variant="secondary" onClick={handleBack} />
        {currentStep === totalSteps ? (
          <Button label="Done" variant="primary" onClick={handleDone} />
        ) : (
          <Button label="next" variant="primary" onClick={handleNext} />
        )}
      </div>
    </div>
  );
};

export default MultiFormNav;