import React from "react";
import TwoColumnLayout from "../../components/layouts/TwoColumnLayout";
import SignUp from "../../components/layouts/SignUp";
import TypingAnimation from "../../components/ui/TypingAnimation";
import Powered from "../../components/ui/Powered";
import Button from "../../components/ui/Button";

function SignInPage() {
  return (
    <div className="bg-gradient-to-r from-gray-900  via-blue-950 via-80% to-blue-800">
    <TwoColumnLayout
      leftContent={
        <div className="flex flex-col min-h-full min-w-full place-items-center justify-center">
            <TypingAnimation text="The real issue, are bad issues." />
            <Button label={'watch demo'} className="place-self-start ml-32 mt-4" onClick={() => { 
              window.open('https://www.floik.com/flos/eilf/zcu1/1de941c5.html?skip-video=true', '_blank', 'location=yes,height=1024,width=768,scrollbars=yes,status=yes')
            }} />
            <Powered />
        </div>
      }
      rightContent={
        <div className="flex h-full content-center justify-center">
            <SignUp />
        </div>
      }
    />
    </div>
  );
}

export default SignInPage;
