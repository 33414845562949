import React from "react";
import TwoColumnLayout from "../../components/layouts/TwoColumnLayout";
import InitialForm from "../../components/layouts/InitialForm";
import IssueExample from "../../components/layouts/IssueExample";



function Setup() {
  
  return (
    <TwoColumnLayout
      leftContent={
        <div className="flex min-h-full min-w-full p-16 m-0 bg-gray-900">
          <InitialForm />
        </div>
      }
      rightContent={
        <div className="flex min-h-full min-w-full p-16 m-0 bg-blue-950">
          <IssueExample
          />
        </div>
      }
    />
  );
}

export default Setup;
