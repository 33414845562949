import React, { useEffect } from "react";
import TextArea from "../../ui/TextArea";
import Select from "../../ui/Select";
import Button from "../../ui/Button";
import ButtonIconed from "../../ui/ButtonIconed";
import Tooltip from "../../ui/Tooltip";
import { useAtom } from "jotai";
import {
  repositoryListAtom,
  formDataAtom,
  lingoListAtom,
  isGeneratingAtom,
} from "../../../pages/IssueCreation";
import { toastAtom } from "../../../App";


const getItemFromLocalStorage = (key, defaultValue = null) => {
  return localStorage.getItem(key) || defaultValue;
};


const IssueForm = () => {
  const [repositoryList, setRepositoryList] = useAtom(repositoryListAtom);
  const [lingoList, setLingoList] = useAtom(lingoListAtom);
  const [formData, setFormData] = useAtom(formDataAtom);
  const [, setToast] = useAtom(toastAtom);
  const [isGenerating, setIsGenerating] = useAtom(isGeneratingAtom);


  useEffect(() => {
    const user_id = getItemFromLocalStorage("user_id");

    fetch("https://api.theissue.ai/repos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: "https://theissue.ai",
      },
      body: JSON.stringify({ user_id }),
    })
      .then((response) => response.json())
      
      .then((json) => {
        const repoNames = json.repos.map((repo) => ({
            value: repo.name,
            label: repo.name,
            owner: repo.owner,
        }));
    
        // Add special option
        repoNames.push({ value: "ADD_REPOS", label: "Add Repos" });
    
        setRepositoryList(repoNames);
    
        // Check local storage for saved repository
        const savedRepo = localStorage.getItem('repository');
        if (savedRepo && repoNames.some(repo => repo.value === savedRepo)) {
            setFormData((prev) => ({
                ...prev,
                repository: savedRepo,
                owner: repoNames.find((repo) => repo.value === savedRepo).owner,
            }));
        } else if (repoNames.length > 0 && repoNames[0].value !== "ADD_REPOS") {
            setFormData((prev) => ({
                ...prev,
                repository: repoNames[0].value,
                owner: repoNames[0].owner,
            }));
        }
    }).catch((error) => {
        // Clear the local storage and redirect to the /sign-in page
        localStorage.clear();
        window.location.href = "https://theissue.ai/sign-in";
        // setToast({message: `Error listing repos ${error}`, icon: "error", isVisible: true});
      });
  }, [setToast, setFormData, setRepositoryList]);

  useEffect(() => {
    const user_id = getItemFromLocalStorage("user_id");

    fetch("https://api.theissue.ai/lingo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: "https://theissue.ai",
      },
      body: JSON.stringify({ user_id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        const lingoNames = json.lingos.map((lingo) => ({
            value: lingo,
            label: lingo,
        }));
    
        setLingoList(lingoNames);
    
        // Check local storage for saved lingo
        const savedLingo = localStorage.getItem('lingo');
        // if the list of lingos is not empty set a local storage value called has_lingo
        if (lingoNames.length > 0) {
            localStorage.setItem('has_lingo', true);
        } else {
            localStorage.setItem('has_lingo', false);
            //navigate to the setup page
            window.location.href = "https://theissue.ai/setup";
        }

        if (savedLingo && lingoNames.some(lingo => lingo.value === savedLingo)) {
            setFormData((prev) => ({ ...prev, lingo: savedLingo }));
        } else if (lingoNames.length > 0) {
            setFormData((prev) => ({ ...prev, lingo: lingoNames[0].value }));
        }
    })
    
      .catch((error) => {
        setToast({message: `Error listing lingos: ${error}`, icon: "error", isVisible: true});
      });
  }, [setToast, setLingoList, setFormData]);

  //TODO: On Click, generate the preview of the issue by calling the backend
  const onGenerate = () => {
    // set loading state
    setIsGenerating(true);
    
    fetch("https://api.theissue.ai/generate_issue", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'https://theissue.ai',
      },
      body: JSON.stringify({
        repository: formData.repository,
        owner: formData.owner,
        issueTitle: formData.issueTitle,
        issueDescription: formData.issueDescription,
        lingo: formData.lingo,
        user_id: localStorage.getItem('user_id'),
      })
    })
    .then((response) => response.json())
    .then((json) => {
      // set the preview data to the response
      setFormData((prev) => ({ ...prev, issuePreview: json.issuePreview }));
      setIsGenerating(false); // move this here
    })
    .catch((error) => {
      // Show toast error message
      setToast({message: `Error generating issue: ${error}`, icon: "error", isVisible: true});
      setIsGenerating(false); // also set the state to false if there was an error
    });
  }
  

  const backToSetup = () => {
    window.location.href = "/new-lingo";
  };

  return (
    <div className="flex flex-col min-w-full min-h-full">
      <div className="flex flex-row w-full min-h-min gap-6">
        <div className="flex flex-col w-1/2">
          <h2 className="text-white text-2xl normal-case">Generate issue</h2>
        </div>
      </div>
      <div className="flex flex-row w-full min-h-min pt-6 gap-6">
        <div className="flex flex-col w-1/2">
          <Tooltip 
          content="
          Select the repository where you want to create the issue
          If you can't find the repo, please make sure you have installed it.
          on Github and on the desired level (user or organization).
          "
          position="right-top">
          <Select
            label="REPO:"
            options={repositoryList || []}
            value={formData.repository || ""}
            onChange={(value) => {
              if (value === "ADD_REPOS") {
                  // Open the desired URL in a new tab
                  window.open("https://github.com/apps/the-issue-is/installations/select_target", "_blank");
                  return;
              }
              setFormData((prev) => ({
                  ...prev,
                  repository: value,
                  owner: repositoryList.find((repo) => repo.value === value)?.owner,
              }));
          }}
          />
          </Tooltip>
        </div>
        <div className="flex flex-col w-1/2">
          <Select
            label="LINGO:"
            options={lingoList || []}
            value={formData.lingo || ""}
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, lingo: value }))
            }
          />
        </div>
      </div>
      <div className="flex flex-row w-full min-h-min gap-6 mt-6">
        <div className="flex flex-col w-full">
          <TextArea
            placeholder="Issue title"
            lines="1"
            value={formData.issueTitle || ""}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, issueTitle: e.target.value }))
            }
          />
        </div>
      </div>
      <div className="flex flex-row w-full min-h-full gap-6 mt-6 flex-grow">
        <div className="flex flex-col w-full min-h-full">
          <TextArea
            placeholder="Description"
            value={formData.issueDescription || ""}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                issueDescription: e.target.value,
              }))
            }
          />
        </div>
      </div>
      <div className="flex flex-col min-w-full max-h-full">
        <div className="flex justify-between mt-4 mb-0 mr-0">
        <ButtonIconed icon='cog' onClick={() => backToSetup()}/>
        <Button 
    className="justify-self-end mr-0" 
    label={isGenerating ? 'Generating ...' : 'Generate'} 
    onClick={() => onGenerate()} 
    disabled={isGenerating || !formData.issueTitle || !formData.issueDescription}
/>        </div>
      </div>
    </div>
  );
};

export default IssueForm;
