import React, { useState } from "react";

const tickIcon =
<svg width="24" height="24" viewBox="0 0 24 24">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>

const MultiSelectOption = ({ options, onChange, defaultValues = [], ...props }) => {
  const [selectedValues, setSelectedValues] = useState(defaultValues);

  const handleOptionClick = (value, isDisabled) => {
    if (isDisabled) return; // Prevent changes if the option is disabled

    const newSelectedValues = [...selectedValues];
    if (newSelectedValues.includes(value)) {
      const index = newSelectedValues.indexOf(value);
      newSelectedValues.splice(index, 1);
    } else {
      newSelectedValues.push(value);
    }

    setSelectedValues(newSelectedValues);
    if (onChange) {
      onChange(newSelectedValues);
    }
  };

  return (
    <div className="flex-col">
      {options.map((option) => (
        <div 
          key={option.value} 
          className={
            `cursor-pointer
            flex
            items-center
            mb-4
            relative 
            px-10 py-5
            border-2
            border-blue-900 hover:border-blue-400
            rounded-none
            ${selectedValues.includes(option.value) ? '  text-white-950 font-bold' : 'text-white'}
            ${option.disabled ? 'bg-slate-800 cursor-not-allowed text-slate-600 border-none' : ''}`
          }
          onClick={() => handleOptionClick(option.value, option.disabled)}
        >
          <div className="justify-start">  
            {option.label}
          </div>
          <span className="stroke-current stroke-[1.5] fill-none w-max h-max ml-auto bg-blue-400">
            {selectedValues.includes(option.value) && tickIcon}
          </span>
        </div>
      ))}
    </div>
  );
};

export default MultiSelectOption;
