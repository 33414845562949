import React from "react";
import Button from "../../ui/Button";
import Tooltip from "../../ui/Tooltip";


// get the .env variables
const CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID;

//read the url parameters
const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get("code");

// if the code is present, then we are coming from github
if (code) {
  // fetch this endpoint and print it to the console (pass the code as a parameter) /access_token/:code
  // Ensure the 'code' variable is defined before this point
  fetch("https://api.theissue.ai/access_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Origin: "https://theissue.ai",
    },
    body: JSON.stringify({ code: code }),
  })
    .then((response) => response.json())
    .then((json) => {
      localStorage.setItem("user_id", json.user_id);

      // redirect to the setup page
      window.location.href = "/setup";
    })
    .catch((error) => {

      console.error("Error:", error);
      //clear the local storage
      localStorage.clear();
      // redirect to the /signup page
      window.location.href =
        "https://github.com/login/oauth/authorize?client_id=" +
        CLIENT_ID +
        "&redirect_uri=https://theissue.ai/";
    });
}

const onConnectToGithub = () => {
  //redirect to this url to connect to github `https://github.com/apps/the-issue-is/installations/new`;
  window.location.href =
    "https://github.com/login/oauth/authorize?client_id=" +
    CLIENT_ID +
    "&redirect_uri=https://theissue.ai/";
};

const SignUp = () => {
  return (
    <div className="flex-col h-3/6 self-center bg-gray-900">
      <div className="flex flex-col justify-evenly items-center px-8 h-full border-2 border-blue-950 ">
        <h2 className="text-3xl text-center">Connect to Github</h2>
        <p className="text-center  text-white/60 w-2/3 leading-loose">
          <Tooltip content={`
          Permissions required: Read metadata | Read and write issues and pull requests. You can revoke them at any time.`}
          position="top-right"
          >
          <span className="underline decoration-blue-700">Connect your GitHub account</span>
          </Tooltip>
          {" "} to start creating better issues.
        </p>
        <Button label="Connect to Github" onClick={onConnectToGithub} />
        <p className="text-center text-sm text-white/60 w-2/3 leading-loose">
          By clicking, you will be accepting our <br />
          <a className="underline decoration-blue-700" href="/terms">terms of service</a>
          {" "}and{" "}
          <a className="underline decoration-blue-700" href="/privacy">privacy conditions.</a>
        </p>
      </div>
      <div className="flex flex-col justify-self-end justify-evenly items-center px-8 pt-8 pb-3 border-t-0 border-x-2 border-b-2 bg-gray-900 border-blue-950">
        <p>Already have an account?</p>
        <Button label="Log in" variant="terciary" onClick={onConnectToGithub} />
      </div>
    </div>
  );
};

export default SignUp;
